/* eslint-disable react/no-array-index-key */
import React from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import { KoliInfo } from 'commons/types/packingTask.interface';
import { RootReducerInterface } from 'redux-stores/reducers';

import { IShippingLabelMappedData } from '../../interfaces';

import S from './ShippingLabelPrintTemplate.styles';

const ShippingLabelPrintTemplate: React.FC = () => {
  document.body.className = 'print-template';
  const [searchParams] = useSearchParams();
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );
  const koli: KoliInfo[] = JSON.parse(searchParams.get('koli') || '');
  const data: IShippingLabelMappedData = JSON.parse(
    searchParams.get('data') || '',
  );

  const renderLabel = () => (
    <Grid container>
      {/* Header */}
      <Grid item xs={4}>
        <S.SwipeRxLogo src="/assets/swiperx-logo.svg" alt="Logo" />
      </Grid>
      <Grid item xs={5}>
        <S.DigitalSignatureLabel>
          {data.is_digital_signed ? 'SP Electronic' : 'SP Manual'}
        </S.DigitalSignatureLabel>
      </Grid>
      <S.TopRightInformation item xs={3}>
        <p>{data.logistic_name}</p>
      </S.TopRightInformation>

      {/* Content */}
      <S.ContentGrid item xs={3}>
        Date
      </S.ContentGrid>
      <S.ContentGrid item xs={6}>
        : {data.order_date}
      </S.ContentGrid>
      <S.TopRightInformation item xs={3}>
        {data.priority}
      </S.TopRightInformation>
      <S.ContentGrid item xs={3}>
        SO
      </S.ContentGrid>
      <S.ContentGrid item xs={6}>
        : {data.po_number}
      </S.ContentGrid>
      <S.TopRightInformation item xs={3} />
      <S.ContentGrid item xs={3}>
        Pharmacy Name
      </S.ContentGrid>
      <S.ContentGrid item xs={9}>
        : {data.pharmacy?.name || ''}
      </S.ContentGrid>
      <S.ContentGrid item xs={3}>
        Address
      </S.ContentGrid>
      <S.ContentGrid item xs={9}>
        : {data.pharmacy?.address || ''}
      </S.ContentGrid>
      <S.QRCodeWrapper item xs={3}>
        <QRCode value={data.po_number} size={48} />
        <S.QRText>SO Number</S.QRText>
      </S.QRCodeWrapper>
      <S.PharmacyDetails item xs={5}>
        <p>City: {data.pharmacy?.city || ''}</p>
        <p>District: {data.pharmacy?.district || ''}</p>
        <p>Sub-District: {data.pharmacy?.subdistrict || ''}</p>
        <p>Postal Code: {data.pharmacy?.zipcode || ''}</p>
      </S.PharmacyDetails>
      <S.QRCodeWrapper item xs={3}>
        {!!data.awb_number && (
          <>
            <QRCode value={data.awb_number} size={48} />
            <S.QRText>SwipeRX AWB</S.QRText>
          </>
        )}
      </S.QRCodeWrapper>

      {/* Footer */}
      <Grid item xs={8} />
      <S.BasketGrid item xs={4}>
        Basket: {data.baskets?.join(', ')}
      </S.BasketGrid>
    </Grid>
  );

  return koli.map((_, idx) => (
    <S.PageBody>
      {renderLabel()}
      {idx < koli.length - 1 && (
        <div
          className="pagebreak-after"
          style={{ marginTop: '2mm', width: '100%' }}
        />
      )}
    </S.PageBody>
  ));
};

export default ShippingLabelPrintTemplate;
