import { DateTime } from 'luxon';

import { PickingTask } from 'commons/types';

import { IShippingLabelMappedData } from '../interfaces';

export const mapShippingLabelPrintData = (
  pickingTask: PickingTask,
): IShippingLabelMappedData => {
  return {
    is_digital_signed: pickingTask.is_digital_signed || false,
    logistic_name: pickingTask.logistic_partner_name || '',
    order_date: DateTime.fromISO(
      pickingTask.createdAt as unknown as string,
    ).toFormat('dd LLL yyyy'),
    priority: pickingTask.priority,
    po_number: pickingTask.po_number,
    pharmacy: pickingTask.pharmacy || null,
    baskets: pickingTask.baskets,
    awb_number: pickingTask.awb_number || '',
  };
};
