import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from '@mui/material';

import * as S from 'commons/components/Table/Table.styles';
import { LIGHT_GRAY, PRIMARY_RED, PRIMARY_YELLOW } from 'commons/styles/colors';
import { DataTableProps } from 'commons/types/basketInfoData.interface';
import { BasketProductDetails } from 'commons/types/packingTask.interface';
import { RootReducerInterface } from 'redux-stores/reducers';
import { checkItem } from 'redux-stores/reducers/packingTaskReducer';
import { AppDispatch } from 'redux-stores/store';

import { CheckBasketStyle as SB } from '../CheckBasket.styles';

import { BasketInfoDataTable } from './BasketInfoDataTable.component';
import EditQtyModal from './EditQtyModal.component';

const Row: React.FC<{
  data: BasketProductDetails;
  onCheckboxClick: (itemIndex: number) => void;
  itemIndex: number;
  basketNumber: number;
  getTotalQtyBySkuCode: (skuCode: string) => number;
}> = ({
  data,
  onCheckboxClick,
  itemIndex,
  basketNumber,
  getTotalQtyBySkuCode,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenDialog = (): void => {
    setOpen(true);
  };

  const handleCloseDialog = (): void => {
    setOpen(false);
  };

  return (
    <>
      <S.TableRow>
        <S.TableColumn>
          <SB.BoldText>{data.sku_code}</SB.BoldText>
          {data.is_modified ? <SB.GreenText>Modified</SB.GreenText> : null}
        </S.TableColumn>
        <S.TableColumn>{data.name}</S.TableColumn>
        <S.TableColumn>{data.uom}</S.TableColumn>
        <S.TableColumn style={{ textAlign: 'center' }}>
          {data.quantity_ordered}
        </S.TableColumn>
        <S.TableColumn style={{ textAlign: 'center' }}>
          <SB.QtyPicked
            style={{
              borderColor:
                getTotalQtyBySkuCode(data.sku_code) > data.quantity_ordered
                  ? PRIMARY_RED
                  : getTotalQtyBySkuCode(data.sku_code) < data.quantity_ordered
                  ? PRIMARY_YELLOW
                  : LIGHT_GRAY,
            }}
            onClick={() => handleOpenDialog()}
          >
            {data.total_qty_picked}
          </SB.QtyPicked>
        </S.TableColumn>
        <S.TableColumn style={{ textAlign: 'center' }}>
          <Checkbox
            onClick={() => onCheckboxClick(itemIndex)}
            checked={data.check_passed}
            disabled={
              getTotalQtyBySkuCode(data.sku_code) > data.quantity_ordered
            }
          />
        </S.TableColumn>
      </S.TableRow>
      <EditQtyModal
        openModal={open}
        onClose={() => handleCloseDialog()}
        data={data}
        basketNumber={basketNumber}
      />
    </>
  );
};

export const BasketInfoTable: React.FC<DataTableProps> = (
  params,
): React.ReactNode => {
  const {
    data = [],
    rowTitles,
    basketIndex,
    basketNumber,
    getTotalQtyBySkuCode,
  }: DataTableProps = params;

  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );
  const dispatch = useDispatch<AppDispatch>();

  const [checkAll, setCheckAll] = useState<boolean>(false);

  const handleCheckPassed = (itemIndex: number): void => {
    dispatch(checkItem({ basketIndex, itemIndex }));
  };

  const handleCheckAll = (): void => {
    const clonedTask = structuredClone(packingTask);
    if (clonedTask) {
      if (checkAll) {
        for (let itemIndex = 0; itemIndex < data.length; itemIndex += 1) {
          dispatch(checkItem({ basketIndex, itemIndex }));
        }
      } else {
        for (let itemIndex = 0; itemIndex < data.length; itemIndex += 1) {
          // if not checked all changed all not checked to checked
          if (
            !clonedTask.basket_infos[basketIndex].items[itemIndex].check_passed
          ) {
            dispatch(checkItem({ basketIndex, itemIndex }));
          }
        }
      }
    }
  };

  const isAllCheckEnabled = (): boolean => {
    if (data) {
      return data.every(
        (basket) =>
          getTotalQtyBySkuCode(basket.sku_code) <= basket.quantity_ordered,
      );
    } else {
      return false;
    }
  };

  useMemo(() => {
    setCheckAll(data.every((basket) => basket.check_passed === true));
  }, [data]);

  return (
    <BasketInfoDataTable
      handleCheckAll={handleCheckAll}
      checkAll={checkAll}
      rowTitles={rowTitles}
    >
      <S.TableBody>
        <S.TableRow>
          <S.TableColumn />
          <S.TableColumn />
          <S.TableColumn />
          <S.TableColumn />
          <S.TableColumn />
          <S.TableColumn style={{ textAlign: 'center' }}>
            <Checkbox
              onClick={() => handleCheckAll()}
              checked={checkAll}
              disabled={!isAllCheckEnabled()}
            />
          </S.TableColumn>
        </S.TableRow>
        {data.map((item, index) => (
          <Row
            data={item}
            key={item.sku_code}
            onCheckboxClick={handleCheckPassed}
            itemIndex={index}
            getTotalQtyBySkuCode={getTotalQtyBySkuCode}
            basketNumber={basketNumber}
          />
        ))}
      </S.TableBody>
    </BasketInfoDataTable>
  );
};
