import { Accordion, Button, Grid, IconButton, TextField } from '@mui/material';
import styled from 'styled-components';

import { TableColumn } from 'commons/components/Table';
import CommonComponentStyle from 'commons/styles/basic-components.styles';

class CheckBasketPageStyle extends CommonComponentStyle {
  BasketInfoHeader = styled.div`
    display: flex;
    gap: 12px;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
  `;

  VerticalLine = styled.div`
    border: 1px solid ${this.colors.PRIMARY_BLACK};
    height: 100%;
    width: 0;
    opacity: 0.1;
  `;

  HorizontalLine = styled.div`
    border: 1px solid ${this.colors.PRIMARY_BLACK};
    height: 0;
    opacity: 0.1;
  `;

  TakePhotoSection = styled.div`
    color: ${this.colors.PRIMARY_BLUE};
    display: flex;
    align-items: center;
    gap: 6px;
  `;

  Accordion = styled(Accordion)`
    box-shadow: none !important;
    position: inherit !important;
  `;

  QtyPicked = styled.button`
    border-width: 1px;
    border-color: ${this.colors.LIGHT_GRAY};
    border-style: solid;
    padding: 8px;
    width: 69px;
    border-radius: 4px;
    text-align: right;
    margin: auto;
    background-color: ${this.colors.PRIMARY_WHITE};
  `;

  DrawerHeader = styled(Grid)`
    background-color: ${this.colors.LIGHTER_GRAY};
    padding: 8px;
  `;

  BoldText = styled.div`
    font-weight: 700;
    width: fit-content;
  `;

  GreenText = styled.div`
    color: ${this.colors.PRIMARY_GREEN};
  `;

  SKUSection = styled(Grid)`
    background-color: #1733e80d;
    margin: 16px;
    padding: 16px;
    width: inherit !important;
  `;

  Flex = styled.div`
    display: flex;
  `;

  TableWrapper = styled.div`
    padding: 16px;
  `;

  ModalFooterWrapper = styled(this.FooterWrapper)`
    position: relative;
    box-shadow: 0px -2px 8px 0px #0000001a;
    margin-top: 16px;
  `;

  EditQty = styled(TextField)`
    border-width: 1px;
    border-color: ${this.colors.LIGHT_GRAY};
    border-style: solid;
    padding: 8px;
    border-radius: 4px;
    text-align: right;
  `;

  EditTableColumn = styled(TableColumn)`
    width: 20%;
    padding-right: 8px;
    white-space: nowrap;
  `;

  RemoveBatchColumn = styled(TableColumn)`
    width: inherit;
  `;

  Wrapper = styled.div`
    padding: 8px;
  `;

  SelectMenu = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 16px 12px 16px;
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px #0000001a;
  `;

  SelectOption = styled(Button)`
    justify-content: flex-start !important;
    color: ${this.colors.PRIMARY_BLACK} !important;
  `;

  ProgressSpan = styled.span`
    color: ${this.colors.PRIMARY_GRAY};
    display: flex;
    align-items: center;
  `;

  BasketPhotoWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  `;

  BasketPhoto = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 8px;
  `;

  DeletePhoto = styled(IconButton)`
    position: absolute !important;
    padding: 0 !important;
    min-width: 0 !important;
    right: -10px;
    top: -10px;
  `;

  CameraWrapper = styled.div`
    width: 100vw;
    height: 100vh;
  `;

  CameraHeader = styled(Grid)`
    padding: 16px;
    background-color: ${this.colors.PRIMARY_BLACK};
    color: ${this.colors.PRIMARY_WHITE};
    position: absolute;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  `;

  CameraFooter = styled(this.CameraHeader)`
    bottom: 0;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  `;
}

export const CheckBasketStyle = new CheckBasketPageStyle();
