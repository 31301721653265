import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckCircle } from '@mui/icons-material';
import { Grid, TextField } from '@mui/material';

import { Header } from 'commons/components/Header';
import { OrderStatusEnum } from 'commons/enums';
import { PRIMARY_RED } from 'commons/styles/colors';
import { PickingTask } from 'commons/types';
import { getCompanyInfo } from 'redux-stores/actions/companyInfo.action';
import {
  getPackerOnProgressPackingTask,
  updateKoliInfo,
} from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';
import { swipeRxWmsAPIPackingTask } from 'services/apis/SwipeRxWmsApiPackingTask';

import S from './PackPrintPage.styles';
import { mapInvoicePrintData, mapShippingLabelPrintData } from './utils';

enum PrintPDFTypeEnum {
  INVOICE_TTF = 'invoice-ttf',
  ESP = 'esp',
  SHIPPING_LABEL = 'shipping-label',
}

interface KoliInfoUI {
  weight: string;
}

const PackPrintPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [koli, setKoli] = useState<KoliInfoUI[]>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [isPrinted, setIsPrinted] = useState<{
    [K in PrintPDFTypeEnum]: boolean;
  }>({
    [PrintPDFTypeEnum.INVOICE_TTF]: false,
    [PrintPDFTypeEnum.ESP]: false,
    [PrintPDFTypeEnum.SHIPPING_LABEL]: false,
  });
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );
  const { company } = useSelector(
    (state: RootReducerInterface) => state.company,
  );

  useEffect(() => {
    if (!packingTask) {
      dispatch(getPackerOnProgressPackingTask()).then(() =>
        setDataFetched(true),
      );
    } else {
      const koliUI = packingTask.koli.map((k) => ({
        weight: k.weight.toString(),
      }));
      setKoli(koliUI);
    }
    if (!company) {
      dispatch(getCompanyInfo());
    }
  }, [packingTask, company, dispatch]);

  /** Early return logic */
  if (!packingTask && dataFetched) navigate('/home');
  if (!packingTask) return <div />;
  if (packingTask.picking_task.status !== OrderStatusEnum.PACKING) {
    navigate('/pack-task/check-basket');
    return <div />;
  }

  const setKoliNumber = (number: number) => {
    const clonedKoli = structuredClone(koli);
    if (number >= koli.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < number - koli.length; i++) {
        clonedKoli.push({ weight: '' });
      }
    } else {
      const removedAmt = koli.length - number;
      clonedKoli.splice(number, removedAmt);
    }
    setKoli(clonedKoli);
    // set is printed to false, because koli value changes
    setIsPrinted({ ...isPrinted, [PrintPDFTypeEnum.SHIPPING_LABEL]: false });
  };

  const setKoliInfo = (index: number, weight: string) => {
    const clonedKoli = structuredClone(koli);
    clonedKoli[index] = { weight };
    setKoli(clonedKoli);
  };

  const proceedToNextPage = () => {
    // update koli info to API
    const updatedKoli = koli.map((k) => ({
      weight: parseFloat(k.weight) || 0,
    }));
    dispatch(
      updateKoliInfo({
        poNumber: packingTask.po_number,
        koli: updatedKoli,
      }),
    ).then(() => {
      navigate('/pack-task/allocation-staging');
    });
  };

  const _getPrintURL = (type: PrintPDFTypeEnum) => {
    switch (type) {
      case PrintPDFTypeEnum.INVOICE_TTF:
        return `/print/invoice-ttf?&data=${JSON.stringify(
          mapInvoicePrintData(packingTask, company),
        )}&skip-alert-notif=true`;
      case PrintPDFTypeEnum.SHIPPING_LABEL:
        return `/print/ship-label?koli=${JSON.stringify(
          koli,
        )}&skip-alert-notif=true&data=${JSON.stringify(
          mapShippingLabelPrintData(packingTask.picking_task as PickingTask),
        )}`;
      default:
        return '/';
    }
  };

  const printPDF = async (type: PrintPDFTypeEnum) => {
    if (type === PrintPDFTypeEnum.ESP) {
      const url = await swipeRxWmsAPIPackingTask.getESPDocument(
        packingTask.po_number,
      );
      if (!url) {
        dispatch(
          snackbarSetData({
            open: true,
            message: 'Document URL not found for this task',
            color: PRIMARY_RED,
          }),
        );
      } else {
        const hrefArea = document.createElement('a');
        hrefArea.href = url;
        hrefArea.target = '_blank';
        hrefArea.click();
      }
    } else {
      // trigger print
      const printedArea = document.createElement('iframe');
      printedArea.src = _getPrintURL(type);
      printedArea.hidden = true;
      document.body.appendChild(printedArea);
      setTimeout(() => {
        printedArea?.contentWindow?.focus();
        printedArea?.contentWindow?.print();
        setTimeout(() => document.body.removeChild(printedArea), 2000);
      }, 1000);
    }

    // mark isPrinted
    setIsPrinted({ ...isPrinted, [type]: true });
  };

  return (
    <div>
      <Header
        title="Packing"
        prevPageHandler={() => {
          navigate('/');
        }}
      />

      {/* Page Content */}
      <Grid container>
        {/* Task Information */}
        <Grid item xs={12}>
          <S.MainInfoWrapper container>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">PO Number</p>
              <p>{packingTask?.po_number}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Invoice Number</p>
              <p>{packingTask?.invoice_number}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Picker</p>
              <p>{packingTask?.picking_task.picker}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">3PL</p>
              <p>{packingTask?.picking_task.logistic_partner_name}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Priority Type</p>
              <S.PriorityText>
                {packingTask?.picking_task.priority}
              </S.PriorityText>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Pharmacy Name</p>
              <p>{packingTask?.picking_task.pharmacy?.name || '-'}</p>
            </S.MainInfoItem>
          </S.MainInfoWrapper>
        </Grid>

        {/* Printing Section */}
        <Grid item xs={12}>
          <S.PrintInfoWrapper container>
            <S.PrintInfoItem item xs={9} sm={10} className="inline-flex">
              <CheckCircle
                sx={{
                  color: isPrinted[PrintPDFTypeEnum.INVOICE_TTF]
                    ? 'primary.main'
                    : 'primary.light',
                }}
                style={{ marginRight: 16 }}
              />
              <div>
                <b>PRINT DOCUMENTS</b> <br /> <br />
                Documents include: Invoice and TTF
              </div>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={3} sm={2} className="align-right">
              <S.PrimaryButton
                onClick={() => printPDF(PrintPDFTypeEnum.INVOICE_TTF)}
              >
                Print
              </S.PrimaryButton>
            </S.PrintInfoItem>
          </S.PrintInfoWrapper>
        </Grid>
        {packingTask.picking_task.is_digital_signed && (
          <Grid item xs={12}>
            <S.PrintInfoWrapper container>
              <S.PrintInfoItem item xs={9} sm={10} className="inline-flex">
                <CheckCircle
                  sx={{
                    color: isPrinted[PrintPDFTypeEnum.ESP]
                      ? 'primary.main'
                      : 'primary.light',
                  }}
                  style={{ marginRight: 16 }}
                />
                <div>
                  <b>PRINT ESP</b> <br />
                </div>
              </S.PrintInfoItem>
              <S.PrintInfoItem item xs={3} sm={2} className="align-right">
                <S.PrimaryButton
                  disabled={!isPrinted[PrintPDFTypeEnum.INVOICE_TTF]}
                  onClick={() => printPDF(PrintPDFTypeEnum.ESP)}
                >
                  Print
                </S.PrimaryButton>
              </S.PrintInfoItem>
            </S.PrintInfoWrapper>
          </Grid>
        )}
        <Grid item xs={12}>
          <S.PrintInfoWrapper container>
            <S.PrintInfoItem item xs={9} sm={10} className="inline-flex">
              <CheckCircle
                sx={{
                  color: isPrinted[PrintPDFTypeEnum.SHIPPING_LABEL]
                    ? 'primary.main'
                    : 'primary.light',
                }}
                style={{ marginRight: 16 }}
              />
              <b>PRINT SHIPPING LABEL</b>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={3} sm={2} className="align-right">
              <S.PrimaryButton
                disabled={
                  (packingTask.picking_task.is_digital_signed
                    ? !isPrinted[PrintPDFTypeEnum.ESP]
                    : !isPrinted[PrintPDFTypeEnum.INVOICE_TTF]) ||
                  koli.length === 0
                }
                onClick={() => printPDF(PrintPDFTypeEnum.SHIPPING_LABEL)}
              >
                Print
              </S.PrimaryButton>
            </S.PrintInfoItem>
            {/* input koli section */}
            <S.PrintInfoItem item xs={6} sm={4}>
              <p>Koli Number</p>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={6} sm={8} className="inline-flex">
              <TextField
                variant="outlined"
                placeholder="0"
                type="number"
                value={koli.length || ''}
                onChange={(e) => setKoliNumber(+e.target.value)}
                inputProps={{
                  sx: {
                    textAlign: 'right',
                    '&::placeholder': {
                      textAlign: 'right',
                    },
                  },
                }}
              />
            </S.PrintInfoItem>

            {/* Commented as currently no need to input weight for koli
                Might be needed in the future. Remove completely if the business
                flow is fixed to not use this section.
            */}
            {/* {koli.map((k, idx) => (
              <>
                <S.PrintInfoItem item xs={6} sm={4}>
                  <p>Koli {idx + 1}</p>
                </S.PrintInfoItem>
                <S.PrintInfoItem item xs={6} sm={8} className="inline-flex">
                  <TextField
                    key={`Koli ${idx + 1}`}
                    variant="outlined"
                    placeholder="0"
                    type="number"
                    value={k.weight || ''}
                    onChange={(e) => setKoliInfo(idx, e.target.value)}
                    inputProps={{
                      sx: {
                        textAlign: 'right',
                        '&::placeholder': {
                          textAlign: 'right',
                        },
                      },
                      min: 0,
                    }}
                  />
                  <span>kg</span>
                </S.PrintInfoItem>
              </>
            ))} */}
          </S.PrintInfoWrapper>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.PrimaryButton
          disabled={
            !isPrinted[PrintPDFTypeEnum.INVOICE_TTF] ||
            !isPrinted[PrintPDFTypeEnum.SHIPPING_LABEL]
          }
          onClick={proceedToNextPage}
        >
          PROCEED
        </S.PrimaryButton>
      </S.FooterWrapper>
      <S.BlankWhiteSpace />
    </div>
  );
};

export default PackPrintPage;
