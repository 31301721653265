/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IInvoiceMappedData } from '../../interfaces';
import { InvoicePrintTemplateComponent } from '../invoice-template';
import { TTFPrintTemplateComponent } from '../ttf-template';

const InvoiceTTFPrintTemplate: React.FC = () => {
  document.body.className = 'print-template';
  const [searchParams] = useSearchParams();

  const data: IInvoiceMappedData = JSON.parse(searchParams.get('data') || '');
  const [ttfPageBreak, setTtfPageBreak] = useState<React.JSX.Element[]>([]);

  const setInvoiceMaxPageNumber = (pageNumber: number) => {
    // set ttf page break
    const pageBreak: React.JSX.Element[] = [];
    for (let i = 0; i < pageNumber - 1; i++) {
      pageBreak.push(<div style={{ pageBreakAfter: 'always' }} />);
    }
    setTtfPageBreak(pageBreak);
  };

  return (
    <>
      <InvoicePrintTemplateComponent
        data={data}
        setMaxPageNumber={setInvoiceMaxPageNumber}
      />
      {ttfPageBreak}
      <TTFPrintTemplateComponent data={data} />
    </>
  );
};

export default InvoiceTTFPrintTemplate;
