import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import { OrderStatusEnum } from 'commons/enums';
import {
  getPackerOnProgressPackingTask,
  updateAllocationStaging,
} from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import S from './PackAllocationStaging.styles';

const PackAllocationStaging: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [allocStaging, setAllocStaging] = useState<string | null>(null);
  const [allocSuggestion, setAllocSuggestion] = useState<string>('-');
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );

  useEffect(() => {
    if (!packingTask) {
      dispatch(getPackerOnProgressPackingTask()).then(() =>
        setDataFetched(true),
      );
    } else {
      setAllocStaging(packingTask.allocation_staging);
      // Temporary hide the component following figma's wireframe
      // dispatch(
      //   getAllocationStagingSuggestion({
      //     warehouseId: packingTask.warehouse_id,
      //   }),
      // ).then((data) => {
      //   setAllocSuggestion((data.payload as string | null) || '-');
      // });
    }
  }, [packingTask, dispatch]);

  /** Early return logic */
  if (!packingTask && dataFetched) navigate('/home');
  if (!packingTask) return <div />;
  if (packingTask.picking_task.status !== OrderStatusEnum.PACKING) {
    navigate('/pack-task/check-basket');
    return <div />;
  }

  const proceedToNextPage = async () => {
    // update allocation staging info to API
    dispatch(
      updateAllocationStaging({
        poNumber: packingTask.po_number,
        allocationStaging: allocStaging,
      }),
    ).then(() => {
      navigate('/pack-task/summary');
    });
  };

  return (
    <div>
      <Header
        title="Allocation Staging"
        prevPageHandler={() => {
          navigate('/pack-task/print');
        }}
      />

      {/* Page Content */}
      <Grid container>
        {/* Task Information */}
        <Grid item xs={12}>
          <S.MainInfoWrapper container>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">PO Number</p>
              <p>{packingTask?.po_number}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Invoice Number</p>
              <p>{packingTask?.invoice_number}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Picker</p>
              <p>{packingTask?.picking_task?.picker}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">3PL</p>
              <p>{packingTask?.picking_task.logistic_partner_name}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Priority Type</p>
              <S.PriorityText>
                {packingTask?.picking_task?.priority}
              </S.PriorityText>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Pharmacy Name</p>
              <p>{packingTask?.picking_task.pharmacy?.name || '-'}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Koli</p>
              <p>{packingTask.koli.length}</p>
            </S.MainInfoItem>
          </S.MainInfoWrapper>
        </Grid>

        {/* Allocation Staging Section */}
        <S.AllocationInfoWrapper container>
          <Grid item xs={12}>
            <S.AllocationInfoTitle>ALLOCATION STAGING</S.AllocationInfoTitle>
          </Grid>
          <Grid item xs={12}>
            <S.AllocationInfoTextField
              variant="outlined"
              placeholder="Type manually or scan here. e.g Pallet Borzo 1"
              value={allocStaging || ''}
              onChange={(e) => setAllocStaging(e.target.value || null)}
            />
          </Grid>
          {/* Temporary hide the component following figma's wireframe */}
          <Grid item xs={12} style={{ display: 'none' }}>
            <S.AllocationInfoSuggestionDiv>
              <S.AllocationInfoSuggestionSpan>
                Suggestion: <b>{allocSuggestion}</b>
              </S.AllocationInfoSuggestionSpan>
              <S.SuggestionSecondaryButton
                hidden={allocSuggestion === '-'}
                onClick={() => setAllocStaging(allocSuggestion)}
              >
                USE SUGGESTION
              </S.SuggestionSecondaryButton>
            </S.AllocationInfoSuggestionDiv>
          </Grid>
        </S.AllocationInfoWrapper>
      </Grid>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.PrimaryButton disabled={!allocStaging} onClick={proceedToNextPage}>
          PROCEED
        </S.PrimaryButton>
      </S.FooterWrapper>
      <S.BlankWhiteSpace />
    </div>
  );
};

export default PackAllocationStaging;
