import { DateTime } from 'luxon';

import { ICompanyInfo, OrderType } from 'commons/types';
import { IPackingTask } from 'commons/types/packingTask.interface';

import { IInvoiceMappedData, IRenderedPickingItem } from '../interfaces';

const TABLE_CHUNK = 15;

export const mapInvoicePrintData = (
  packingTask: IPackingTask,
  company: ICompanyInfo | null,
  tableChunk: number = TABLE_CHUNK,
): IInvoiceMappedData => {
  const tableData: IRenderedPickingItem[][] = [];
  const items =
    packingTask.picking_task.items
      ?.map((item) => {
        return item.pick_actual.reduce<IRenderedPickingItem[]>(
          (res, pickActual) => [
            ...res,
            {
              sku_code: item.sku_code,
              name: item.name,
              quantity: pickActual.quantity,
              uom: item.uom || '',
              inventory: pickActual.inventory_number,
              expiry_date: DateTime.fromISO(
                pickActual.expiry_date as unknown as string,
              ).toFormat('LL-yyyy'),
              price: item.price,
              discount: item.discount,
            },
          ],
          [],
        );
      })
      .flat() || [];
  for (let i = 0; i < items.length; i += tableChunk) {
    const chunk = items.slice(i, i + tableChunk);
    tableData.push(chunk);
  }
  const totalAmount = items.reduce(
    (total, productInfo) =>
      total +
      productInfo.price *
        ((100 - productInfo.discount) / 100) *
        productInfo.quantity,
    0,
  );
  const ppn = items.reduce(
    (total, productInfo) =>
      total +
      productInfo.price *
        ((100 - productInfo.discount) / 100) *
        (packingTask.picking_task.tax_rate || 0.11) *
        productInfo.quantity,
    0,
  );

  const apjData =
    company?.apj[packingTask.picking_task?.po_type || OrderType.REGULAR];

  return {
    is_digital_signed: packingTask.picking_task.is_digital_signed || false,
    invoice_number: packingTask.invoice_number || '',
    order_date: DateTime.fromISO(
      packingTask.picking_task.createdAt as unknown as string,
    ).toFormat('dd-LLL-yyyy'),
    po_number: packingTask.po_number,
    po_type: packingTask.picking_task?.po_type || OrderType.REGULAR,
    due_date: DateTime.fromISO(
      packingTask.picking_task.createdAt as unknown as string,
    )
      .plus({ days: 30 })
      .toFormat('dd-LLL-yyyy'),
    customer_id: packingTask?.picking_task?.pharmacy?.pt_id || null,
    customer_name: packingTask?.picking_task?.pharmacy?.name || '',
    customer_address: packingTask?.picking_task?.pharmacy?.address || '',
    items: tableData,
    total_amount: totalAmount,
    ppn,
    company_info: company && {
      address: company.address,
      pbf_no: company.pbf_no,
      dak_no: company.dak_no,
      cdob_no: company.cdob_no,
      apj_signature: apjData?.signature || '',
      apj_name: apjData?.name || '',
      apj_sipa: apjData?.sipa || '',
    },
  };
};
